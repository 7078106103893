import { CalculationResult } from "../../models/shared/CalcultaionResult";
import { Vehicle } from "../../models/shared/Vehicle";
import { ChargeHourPlan, ChargePlan } from '../../models/shared/ChargePlan'
import { DEFAULT_BATTERY_KWH, DEFAULT_CHARGE_SPEED, DEFAULT_SMART_CHARGED_BY_UTC } from "./constants";
import { zeroPad } from "./format";
import { Job } from "../../models/shared/Job";

export function getHourKey(now: Date) {
    const hours = zeroPad(now.getUTCHours());
    return `${getDayKey(now)}-${hours}`;
}
export function getDateFromHourKey(hourKey: string) {
    const year = parseInt(hourKey.substr(0, 4));
    const month = parseInt(hourKey.substr(4, 2)) - 1;
    const day = parseInt(hourKey.substr(6, 2));
    const hours = parseInt(hourKey.substr(9, 2));
    return new Date(Date.UTC(year, month, day, hours));
}
export function getDayKey(now: Date) {
    const month = zeroPad(now.getUTCMonth() + 1);
    const day = zeroPad(now.getUTCDate());
    return `${now.getUTCFullYear()}${month}${day}`;
}
export function getLocalHourKey(now: Date) {
    const hours = zeroPad(now.getHours());
    return `${getLocalDayKey(now)}-${hours}`;
}
export function getLocalDayKey(now: Date) {
    const month = zeroPad(now.getMonth() + 1);
    const day = zeroPad(now.getDate());
    return `${now.getFullYear()}${month}${day}`;
}

export const calculatePrices = (prices: Record<string, number>, startDate: Date, endDate: Date) => {
    const result: CalculationResult = {
        perHour: {},
        perDay: {},
        sum: 0,
        hourSteps: [],
        daySteps: [],
    }
    const averageSources = {
        sums: {} as Record<string, number>,
        counts: {} as Record<string, number>,
    }

    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        const hour = currentDate.getHours();

        const localHourKey = getLocalHourKey(currentDate);
        const localDayKey = getLocalDayKey(currentDate);

        if (!result.daySteps.includes(localDayKey)) {
            result.daySteps.push(localDayKey);
            result.perDay[localDayKey] = 0;
        }
        result.hourSteps.push(localHourKey);
        const value = prices?.[getHourKey(currentDate)] || 0;
        result.perHour[localHourKey] = value;

        averageSources.sums[localDayKey] = (averageSources.sums[localDayKey] || 0) + value;
        averageSources.counts[localDayKey] = (averageSources.counts[localDayKey] || 0) + 1;

        currentDate.setHours(hour + 1);
    }

    for (const dayStep of result.daySteps) {
        const average = averageSources.sums[dayStep] / averageSources.counts[dayStep];
        result.perDay[dayStep] = average;
    }


    return result;
}


export const buildPlanFromJobs = (startsCharging: boolean, jobs: Job[], times: string[]): ChargePlan => {
    const planPerHour: Record<string, ChargeHourPlan> = {};
    const chargeSpeed = DEFAULT_CHARGE_SPEED;
    let charging = startsCharging;

    const chargingJobs = jobs
        .filter(job => job.action === 'startCharge' || job.action === 'stopCharge')
        .filter(job => job.status === 'scheduled')
    let jobIx = 0;

    for (const time of times) {
        const closestJob = chargingJobs[jobIx];
        if (closestJob) {
            const closestJobStartKey = getHourKey(closestJob.scheduledAt);
            if (time >= closestJobStartKey) {
                charging = closestJob.action === 'startCharge';
                jobIx++;
            }
        }

        const consumption = charging ? chargeSpeed : 0;
        planPerHour[time] = {
            consumption,
            speed: chargeSpeed,
            price: 0,
            startState: 0,
        }
    }

    return {
        sortedHourKeys: [],
        planPerHour,
    }
}

export const calculateSmartCharging = (vehicle: Vehicle,
    baterryCharge: number,
    prices: Record<string, number>): ChargePlan => {
    const targetCharge = DEFAULT_CHARGE_SPEED;
    const targetChargePerc = vehicle.chargeTarget || 100;
    const remainingChargePerc = targetChargePerc - baterryCharge;
    const vehicleCapacity = vehicle.batteryKWH || DEFAULT_BATTERY_KWH;
    let remainingChargeKWH = (vehicleCapacity / 100) * remainingChargePerc;

    const start = new Date();
    const end = findPlanEnd(vehicle, start);
    const startKey = getHourKey(start);
    const endKey = getHourKey(end);

    const hourKeys = sortPriceHours(prices, startKey, endKey);

    const planPerHour: Record<string, ChargeHourPlan> = {};

    let hourKeyIx = 0;
    while (remainingChargeKWH > 0 && hourKeyIx < hourKeys.length) {
        const hourKey = hourKeys[hourKeyIx];
        const consumption = Math.min(remainingChargeKWH, targetCharge);
        remainingChargeKWH -= consumption;
        planPerHour[hourKey] = {
            consumption,
            speed: targetCharge,
            price: prices[hourKey],
            startState: 0,
        }
        hourKeyIx++;
    }

    const hourKeysOrdered = Object.keys(planPerHour);
    hourKeysOrdered.sort();
    let currentState = baterryCharge;
    for (const hourKey of hourKeysOrdered) {
        const hourPlan = planPerHour[hourKey];
        if (!hourPlan) {
            continue;
        }
        hourPlan.startState = currentState;
        currentState += hourPlan.consumption;
    }


    return {
        planPerHour,
        sortedHourKeys: hourKeysOrdered,
    };
}

export const getHourKeys = (start: string, end: string) => {
    const result: string[] = [];
    const startDate = getDateFromHourKey(start);
    const endDate = getDateFromHourKey(end);

    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        result.push(getHourKey(currentDate));
        currentDate.setHours(currentDate.getHours() + 1);
    }

    return result;
}

function sortPriceHours(prices: Record<string, number>, startKey: string, endKey: string) {
    const allKeys = Object.keys(prices);
    const validKeys = allKeys.filter(key => key >= startKey && key <= endKey);

    validKeys.sort();
    validKeys.sort((a, b) => prices[a] - prices[b]);

    return validKeys;
}


function findPlanEnd(vehicle: Vehicle, start: Date) {
    const candidate = new Date(start);
    if (!vehicle.useChargedBy) {
        candidate.setDate(candidate.getDate() + 2);
        return candidate;
    }
    const endTime = vehicle.chargedByUTC || DEFAULT_SMART_CHARGED_BY_UTC;
    const endHour = Number(endTime.split(':')[0]);


    while (endHour !== candidate.getUTCHours()) {
        candidate.setHours(candidate.getHours() + 1);
    }
    return candidate;
}


