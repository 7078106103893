import { FC, useMemo, useState } from 'react'
import { texts } from '../utils/texts'
import { Job, JobAction } from '../models/shared/Job'
import { useFirestore, useUser } from 'reactfire'
import { addDoc, collection, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore'
import { COLLECTIONS, DEFAULT_CURRENT_BATTERY_PERCENTAGE, DEFAULT_SMART_CHARGED_BY_UTC, EL_PRICES_KEYS, PRIMARY_COLOR } from '../utils/shared/constants'
import { toast } from 'react-toastify'
import { Connection } from '../models/shared/Connection'
import { Dropdown, Form, SplitButton } from 'react-bootstrap'
import ChargingPlanChart from './ChargingPlanChart'
import { useFirebaseDoc } from '../hooks/firebaseHooks'
import { ElPrices } from '../models/shared/ElPrices'
import { Vehicle } from '../models/shared/Vehicle'
import { Status } from '../models/shared/Status'
import { calculateSmartCharging, getDateFromHourKey, getHourKey } from '../utils/shared/calculations'
import Slider from 'rc-slider'
import { zeroPad } from '../utils/shared/format'

interface IProps {
    connection: Connection
    vehicle: Vehicle
    setVehicle: (Vehicle: Vehicle) => void
    latestStatus: Status | null
    jobs: Job[],
}

/**
* @author
* @function @ChargingActions\
**/

export const ChargingActions: FC<IProps> = (props) => {
    const { vehicle } = props;
    const [, setProcessing] = useState(false)
    const { data: user } = useUser();
    const firestore = useFirestore();
    const [swedenPrices] = useFirebaseDoc<ElPrices>(`${COLLECTIONS.EL_PRICES}/${EL_PRICES_KEYS.SWEDEN}`);
    const prices = useMemo(() => swedenPrices?.SE3 || {}, [swedenPrices]);

    const currentBattery = props.latestStatus?.batteryCharge || DEFAULT_CURRENT_BATTERY_PERCENTAGE;
    const chargePlan = useMemo(() => {
        return calculateSmartCharging(vehicle, currentBattery, prices)
    }, [vehicle, currentBattery, prices]);


    const updateVehicle = async (vehicleUpdate: Partial<Vehicle>) => {
        props.setVehicle({ ...vehicle, ...vehicleUpdate });

        await updateDoc(doc(firestore,
            COLLECTIONS.VEHICLES, vehicle.id || ''), vehicleUpdate);
    }

    const sendJob = async (action: JobAction, scheduledAt?: Date) => {
        setProcessing(true);

        const finalScheduledAt = scheduledAt || new Date();
        const newJob: Job = {
            action,
            connectionId: props.connection.id || '',
            vehicleId: vehicle.id || '',
            createdAt: new Date(),
            scheduledAt: finalScheduledAt,
            updatedAt: new Date(),
            status: 'scheduled',
            uid: user?.uid || ''
        };
        try {
            await addDoc(collection(firestore, COLLECTIONS.JOBS), newJob)
            toast.success(texts.actionScheduled);
        } catch (e) {
            toast.error((e as Error).message);
        }

        setProcessing(false);
    }

    const deleteAllUpcomingActions = async () => {
        const q = query(collection(firestore, COLLECTIONS.JOBS), where('uid', '==', user?.uid), where('status', '==', 'scheduled'));
        const jobsSnap = await getDocs(q);

        jobsSnap.forEach(async (doc) => {
            await setDoc(doc.ref, { status: 'cancelled' }, { merge: true });
        });
    }

    const handleSchedulePlan = async () => {
        setProcessing(true);

        await deleteAllUpcomingActions();

        let charging = false;
        for (const hourKey of chargePlan.sortedHourKeys) {
            const hour = chargePlan.planPerHour[hourKey];
            const shouldCharge = hour.consumption;
            const startTime = getDateFromHourKey(hourKey);

            if (charging && !shouldCharge) {
                await sendJob('stopCharge', startTime);
                charging = false;
            } else if (!charging && shouldCharge) {
                await sendJob('startCharge', startTime);
                charging = true;
            }


            if (!charging) {
                continue;
            }
            const endTime = new Date(startTime);
            endTime.setHours(endTime.getHours() + 1);
            const nextHourKey = getHourKey(endTime);
            if (chargePlan.planPerHour[nextHourKey]?.consumption) {
                continue;
            }

            await sendJob('stopCharge', endTime);
            charging = false;
        }


        setProcessing(false);
    }

    const minValue = props.latestStatus?.batteryCharge || 0;
    const marks: Record<number, any> = {};
    for (let i = 0; i <= 100; i += 10) {
        let style: any = {}
        if (i < minValue) {
            style.color = 'green';
            style.fontWeight = 'bold';
        }
        marks[i] = { label: i + '', style };

    }

    return (<>
        <div className='my-5'>
            <h5>{texts.charging}</h5>

            <h6 className='mt-3'>Charge By</h6>
            <div className='d-flex'>
                <Form.Check type='switch' checked={vehicle.useChargedBy} onChange={() => updateVehicle({ useChargedBy: !vehicle.useChargedBy })} />
                <select className="form-select" style={{ width: '10ch' }} value={vehicle.chargedByUTC || DEFAULT_SMART_CHARGED_BY_UTC}
                    onChange={(e) => updateVehicle({ chargedByUTC: e.target.value })} disabled={!vehicle.useChargedBy}
                >
                    {getHourOptions()}
                </select>
            </div>

            <h6 className='mt-3'>Charge Target</h6>

            <Slider className='mb-5' marks={marks}
                styles={{ track: { backgroundColor: PRIMARY_COLOR } }}
                value={vehicle.chargeTarget || 100}
                onChange={newValue => {
                    const finalNewValue = Math.max(newValue as number, minValue);
                    updateVehicle({ chargeTarget: finalNewValue })
                }}

            />

            <ChargingPlanChart prices={prices} plan={chargePlan} jobs={props.jobs} isCharging={props.latestStatus?.isCharging || false} />

            <div className=''>
                <SplitButton onClick={handleSchedulePlan}
                    title={<><i className='bi bi-magic me-2' />Schedule</>}
                >
                    <Dropdown.Item as="button" onClick={() => sendJob('startCharge')}><i className='bi bi-play text-success me-2' />Start</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => sendJob('stopCharge')}><i className='bi bi-stop text-danger me-2' />Stop</Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item as="button" onClick={() => sendJob('lockCar')}><i className='bi bi-lock me-2' />Lock</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => sendJob('unlockCar')}><i className='bi bi-unlock me-2' />Unlock</Dropdown.Item>

                </SplitButton>
            </div>
            <div className='d-flex mt-2 align-content-center'>
                <p className='m-0 me-3'>Anti-charge Guard:</p>

                <Form.Check type='switch' checked={!!vehicle.chargeGuard} onChange={() => updateVehicle({ chargeGuard: !vehicle.chargeGuard })} />
            </div>
        </div >
    </>
    )
}

const getHourOptions = () => {
    let options = [];
    for (let i = 0; i < 24; i++) {
        const testDate = new Date();
        testDate.setHours(i);
        const utcHour = testDate.getUTCHours();
        options.push(<option key={i} value={`${zeroPad(utcHour)}:00`}>{zeroPad(i)}:00</option>);
    }
    return options;
}
