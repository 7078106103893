import { FC } from 'react'
import { Device } from '../../models/shared/Device'
import { Button, CardBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { texts } from '../../utils/texts';
import { executeIOTAction } from '../../utils/api';
import { JobAction } from '../../models/shared/Job';
import * as Slider from '@radix-ui/react-slider';

interface IProps {
    device: Device
    updateDevice: (device: Device) => void
}

/**
* @author
* @function @HomeDevices
**/

export const LightCardBody: FC<IProps> = (props) => {
    const { device } = props;

    const togglePartyMode = async (device: Device) => {
        const newPartyMode = !device.state.partyMode;
        try {
            const newDevice = {
                ...device,
                state: {
                    ...device.state,
                    partyMode: newPartyMode,
                }
            }
            props.updateDevice(newDevice);

            await executeIOTAction(device.id || '', "partyMode", { on: newPartyMode });
            toast.info(texts.actionScheduled);
        }
        catch (e) {
            toast.error((e as Error).message);
        }
    };

    const scheduleAction = async (device: Device, action: JobAction, params: any = {}) => {
        try {
            await executeIOTAction(device.id || '', action, params);
            toast.info(texts.actionScheduled);
        }
        catch (e) {
            toast.error((e as Error).message);
        }
    }

    const supportsColors = device.type === 'light';

    return <CardBody>
        {supportsColors && <>
            <Button variant='outline-primary' className='mr-2' onClick={() => scheduleAction(device, 'setDay')}>
                <i className="bi bi-brightness-high" />
            </Button>
            <Button variant='outline-primary' className='mr-2' onClick={() => scheduleAction(device, 'setEvening')}>
                <i className="bi bi-brightness-low" />
            </Button>
            <Button variant='outline-primary ms-3' className='mr-2' onClick={() => scheduleAction(device, 'random')}>
                <i className="bi bi-shuffle" />
            </Button>
            <Button variant={`${device.state.partyMode ? 'primary' : 'outline-primary'} ms-3`} className='mr-2' onClick={() => togglePartyMode(device)}>
                <i className="bi bi-cake" />
            </Button>
        </>}

        <p className='text-muted m-0 mt-3'>Brightness</p>
        <Slider.Root className="SliderRoot" defaultValue={[device.state.brightness || 0]} max={254} step={1} onValueCommit={values => scheduleAction(device, 'setBrightness', { value: values[0] })}>
            <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
            </Slider.Track>
            <Slider.Thumb className="SliderThumb" aria-label="Volume" />
        </Slider.Root>

        <p className='text-muted m-0 mt-3'>Temperature</p>
        <Slider.Root className="SliderRoot" defaultValue={[device.state.temperature || 0]} max={454} step={1} onValueCommit={values => scheduleAction(device, 'setTemperature', { value: values[0] })}>
            <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
            </Slider.Track>
            <Slider.Thumb className="SliderThumb" aria-label="Volume" />
        </Slider.Root>
    </CardBody>

}

