import { ScheduleInterval } from "../../models/shared/Device";

export const formatDuration = (durationMinutes: number) => {
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;
    return `${hours}h ${minutes}m`;
}

export const convertDateToTime = (date: Date) => `${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}`;

export const convertIntervalToLocalTime = (interval: ScheduleInterval) => {
    return {
        start: convertTimeToLocal(interval.start),
        end: convertTimeToLocal(interval.end),
    }
}

export const convertTimeToLocal = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    return `${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}`;
}

export function convertTimeToUTC(time: string) {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return `${zeroPad(date.getUTCHours())}:${zeroPad(date.getUTCMinutes())}`;
}

export const zeroPad = (num: string | number) => {
    return num.toString().padStart(2, '0');
}

export const formatDate = (input: Date | string, options: DateFormatOptions = {}) => {
    const date = new Date(input);

    if (options.day === 'short') {
        // Return 3 letter day of week
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[date.getDay()];
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${zeroPad(day)}. ${zeroPad(month)}. ${year}`;
}

interface DateFormatOptions {
    day?: 'short'
}
export const formatDateTime = (input: Date | string, options: DateFormatOptions = {}) => {
    const date = new Date(input);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    let day = formatDate(date, options);
    if (options.day === 'short') {
        day += ',';
    }

    return `${day} ${zeroPad(hours)}:${zeroPad(minutes)}`;
}

export const formatAmount = (amount: number) => {
    if (!amount) {
        return '';
    }
    return amount.toFixed(2).replace('.', ',');
}

