export enum QUERY_PARAMS {
    EMAIL_SIGNIN_FINISHED = 'email_signin_finished'
}

export enum LOCAL_STORAGE_KEYS {
    MAGIC_LINK_EMAIL = 'magic_link_email',
}

export enum PATHS {

}

export enum COLLECTIONS {
    CONFIG = 'config',
    USERS = 'users',
    CONNECTIONS = 'connections',
    VEHICLES = 'vehicles',
    STATUSES = 'statuses',
    JOBS = 'jobs',
    DEVICES = 'devices',
    EL_PRICES = 'elprices',
}


export enum EL_PRICES_KEYS {
    SWEDEN = 'sweden',
}

export const DEFAULT_SMART_CHARGED_BY_UTC = '06:00'
export const DEFAULT_BATTERY_KWH = 50;
export const DEFAULT_CURRENT_BATTERY_PERCENTAGE = 20;
export const DEFAULT_CHARGE_SPEED = 2.0; // 10 amps

export const PRIMARY_COLOR = '#593196';

export const EMAIL = 'support@beaver.codes';
export const MAILTO = `mailto:${EMAIL}`;

